export const isAdmin = () => {
  let url = window.location.host
  // console.log( window.location)
  // console.log(url)
  // let url = 'http://www.baidu.com:8080/test?id=123'
  // console.log('---------------')
  // console.log(process.env.NODE_ENV)
  // console.log(process.env)
  if(process.env.NODE_ENV==='production'){
    return url!=='www.jhcs888.com';
  }else if(process.env.NODE_ENV==='test'){
    return url!=='testwww.jhcs888.com';
  }else {
    return process.env.NODE_ENV==='development-admin'
  }

}

export const langFilter = (data) => {
  const lang = localStorage.getItem('lang') || 'cn'
  if (lang === 'cn') {
    return { ...data, ...data.cn }
  } else {
    return { ...data, ...data.en }
  }
}
