import layout from '@/layout/Forestage/index.vue'
export default [
  {
    path: '/',
    redirect: '/Home',
    component: layout,
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/Forestage/Home/index.vue')
      },
      {
        path: '/AboutUs',
        name: 'AboutUs',
        component: () => import('@/views/Forestage/AboutUs/index.vue')
      },
      {
        path: '/Cooperation',
        name: 'Cooperation',
        component: () => import('@/views/Forestage/Cooperation/index.vue')
      },
      {
        path: '/News',
        name: 'News',
        component: () => import('@/views/Forestage/News/index.vue')
      },
      {
        path: '/Contact',
        name: 'Contact',
        component: () => import('@/views/Forestage/Contact/index.vue')
      },
      {
        path: '/NewDetail',
        name: 'NewDetail',
        component: () => import('@/views/Forestage/NewDetail/index.vue')
      },
    ]
  }
]