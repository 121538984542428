<template>
  <div class="layout-container" :class="isMobile?'mobile-h':'h'">
    <mobile-navigation v-if="isMobile" class="mobile-head"></mobile-navigation>
    <navigation v-else></navigation>

    <div>
      <div v-if="isMobile" class="mobile-only"></div>
      <router-view></router-view>
    </div>

    <page-foot v-if="!isMobile"></page-foot>
    <mobilePageFoot v-else></mobilePageFoot>
  </div>
</template>

<script>
import { hasMobile } from '@/utils/mobile.js'
import { debounce } from 'lodash'
export default {
  data() {
    return {
      isMobile:false,
      isSafari:false,
    }
  },
  created() {
    this.isMobile = hasMobile()
  },
  mounted() {
    // window.addEventListener('resize', debounce(() => {
    // this.$store.commit('setMobile', hasMobile());
    // }));
  },
}
</script>

<style lang="scss" scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.h{
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}
.mobile-h{
  height: 100%;
}

.mobile-only{
  height: 225.28px;
  color: #fff;
}
</style>
