import Vue from 'vue'
import VueRouter from 'vue-router'
import frontRoute from './front.js'
import AdminRoute from './admin.js'
import { isAdmin } from '@/utils/http.js'

Vue.use(VueRouter)

let routes = isAdmin() ? AdminRoute : frontRoute

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
export default router
