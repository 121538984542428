<template>
  <div class="container" :style="{background:background}">
    <div :class="isMobile?'m-text-warp':'text-warp'">
      <p class="en animate__animated animate__backInLeft"  ref="enText">{{enText}}</p>
      <p class="cn animate__animated animate__backInRight" ref="cnText">{{cnText}}</p>
    </div>
  </div>
</template>

<script>
import { hasMobile } from '@/utils/mobile.js'
export default {
  name: 'Banner',
  props: ['enText', 'cnText', 'background'],
  data() {
    return {
      enTextAnimate: false,
      cnTextAnimate: false,
      isMobile:false,
    }
  },
  created() {
    this.isMobile = hasMobile()
  },
  methods: {},

}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 710px;
  color: #fff;
}

.m-text-warp{
  position: absolute;
  top: 238.08px;
  left: 176.64px;
  .en{
    font-size: 102.4px;
    font-weight: 500;
  }
  .cn {
    margin-top: 20px;
    font-size: 71.68px;
    font-weight: 500;
  }
}

.text-warp {
  position: absolute;
  top: 268px;
  left: 360px;
  .en {
    font-size: 64px;
    font-weight: 500;
  }
  .cn {
    margin-top: 27px;
    font-size: 36px;
    font-weight: 500;
  }
}
</style>
