<template>
  <div class="footer-container">
    <div class="row_1 flex">
      <img src="@/assets/forestage/images/logo.png" class="logo">
      <ul class="flex-vc">
        <li class="nav-title mouse-poi flex-vc" @click="navClick(1)">{{$t('nav.navText_1')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(2)">{{$t('nav.navText_2')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(3)">{{$t('nav.navText_3')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(4)">{{$t('nav.navText_4')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(5)">{{$t('nav.navText_5')}}</li>
      </ul>
    </div>
    <div class="row_2 flex">
      <div class="warp-left flex-hc">
        <p class="company-msg"><span class="text">{{$t('footer.footText_1')}}</span>：{{companyInfo.tel}}</p>
        <p class="company-msg"><span class="text">{{$t('footer.footText_2')}}</span>：{{companyInfo.email}}</p>
        <!-- <p class="company-msg"><span class="text">{{$t('footer.footText_3')}}</span>：{{$t('footer.footText_6')}}</p> -->
        <p class="company-msg"><span class="text">{{$t('footer.footText_3')}}</span>：{{lang == 'cn'?companyInfo.cn.address:companyInfo.en.address}}</p>
      </div>
      <div class="warp-right flex-vc">
        <div class="inner">
          <p class="phone-msg">{{$t('footer.footText_4')}}</p>
          <p class="phone">{{companyInfo.tel}}</p>
        </div>
        <div class="inner-qr_code">
          <img :src="companyInfo.wx_image" class="qr_code">
          <p class="qr_code-msg">{{$t('footer.footText_5')}}</p>
        </div>
      </div>
    </div>
    <div class="row_3 flex-vh">
      <p>版权公告©2022 重庆华际科技发展有限公司及重庆市钜宏日化有限公司。版权所有。</p>
      <div class="flex-vc">
        <img src="@/assets/forestage/images/bah.png" class="police-icon">
        <p class="mouse-poi" @click="openIcp()">渝ICP备{{companyInfo.icp}}</p>
        <!-- 号 渝公网安备 {{companyInfo.put_on_record}} -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Footer',
  data() {
    return {
      lang:'cn',
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'companyInfo'
    }),
  },

  async created() {
    let that = this;
    window.addEventListener("setItemEvent", function(e) {
      if (e.key === "lang") {
        that.lang = e.newValue;
      }
    })

    await this.$store.dispatch('user/getCompanyInfo')
  },
  methods: {
    openIcp(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    navClick(num) {
      this.activeIndex = num
      switch (num) {
        case 1:
          this.$router.push({ path: '/Home' })
          break;
        case 2:
          this.$router.push({ path: '/AboutUs' })
          break;
        case 3:
          this.$router.push({ path: '/Cooperation' })
          break;
        case 4:
          this.$router.push({ path: '/News' })
          break;
        case 5:
          this.$router.push({ path: '/Contact' })
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.footer-container {
  height: 497px;
  padding: 0 360px;
  background: url('~@/assets/forestage/images/footer.png') no-repeat center / 100% 100%;
}

.row_1 {
  padding: 32px 0;

  .logo {
    width: 144px;
    height: 78px;
  }

  .nav-title {
    margin-right: 38px;
    font-size: 16px;
    color: #ffffff;

    &:hover {
      color: #e2e2e2;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.row_2 {
  height: 250px;
  padding: 30px 0;
  font-size: 16px;
  color: #ffffff;
  border-top: 1px solid rgba(225, 225, 225, 0.15);
  border-bottom: 1px solid rgba(225, 225, 225, 0.15);
  box-sizing: border-box;

  .warp-left {
    flex-direction: column;

    .text {
      display: inline-block;
    }
  }

  .company-msg:nth-child(2) {
    margin-top: 30px;
  }

  .company-msg:nth-child(3) {
    margin-top: 30px;
  }

  .warp-right {
    display: flex;
  }

  .inner {
    margin-right: 30px;

    .phone-msg {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.7);
      text-align: right;
    }

    .phone {
      margin-top: 6px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .inner-qr_code {
    .qr_code {
      width: 150px;
      height: 150px;
    }

    .qr_code-msg {
      margin-top: 19px;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
    }
  }
}

.row_3 {
  flex-direction: column;
  padding: 30px 0;
  font-size: 14px;
  color: #D4D7DB;

  .flex-vc {
    margin-top: 12px;
  }

  .police-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
